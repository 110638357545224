
import React, { useState, useReducer, useEffect, useMemo, useRef } from 'react';
import { Select, MenuItem, InputLabel, ButtonGroup, Menu, Box, Slider, Tooltip, Container, Checkbox, Chip, FormControlLabel, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, TextField, IconButton, useMediaQuery, Divider, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";
import axios from 'axios';


function Templates(props) {
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [userTemplates, setUserTemplates] = useState([]);
    const [inputValue, setInputValue] = useState(''); 

    useEffect(() => {
        const fetchUserTemplates = async () => {
            try {
                const response = await axios.get('https://dodogenerator.pacificrp.ru/templates', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                console.log(response.data)
                setUserTemplates(response.data);
            } catch (error) {
                console.error('Ошибка загрузки пользовательских шаблонов:', error);
            }
        };
        fetchUserTemplates();
    }, []);


    const handleTemplatesChange = (event, values) => {
        setSelectedTemplates(values);
        generateTemplateText(values);
    };

    const generateTemplateText = (templates) => {
        if (templates.length === 0) {
            props.setTemplateText("");
            props.setTemplateImg("");
            return;
        }

        const selectedTexts = templates.map((template) => {
            const option = combinedOptions.find(option => option.key === template.key);
            if (option.img) {
                props.setTemplateImg(option.img);
            }
            return option.text;
        });

        let resultText = selectedTexts.join(" ");

        // if (resultText.endsWith(".")) {
        //     resultText = resultText.slice(0, -1);
        // }

        props.setTemplateText(resultText);
    };


    const options = [
        { name: "Прощание", key: "l1", text: "Если возникнут другие вопросы или трудности — мы рядом.", tag: "Общие" },
        { name: "Оценка диалога", key: "l2", text: "Пожалуйста, уделите минуту для оценки диалога — это поможет нам стать лучше.", tag: "Общие" },
        { name: "Нагрузка на чат", key: "l3", text: "Простите за долгий ответ, поступает много обращений.", tag: "Общие" },

        { name: "Новый адрес вне зоны доставки", key: "l4", text: "Нет технической возможности изменить адрес заказа, его обслуживает другая пиццерия. Можем отменить текущий, чтобы вы оформили новый. Видим в системе, что заказ оплатили картой. Деньги вернутся в течение 3-10 рабочих дней, в зависимости от нагрузки на банк. Отменяем?", tag: "Отмена заказа" },
        { name: "Не можем изменить время", key: "l5", text: "Нет технической возможности изменить время заказа. Можем отменить текущий, чтобы вы оформили новый.", tag: "Отмена заказа" },

        { name: "Сроки возврата", key: "l6", text: "Деньги вернутся в течение 3-10 рабочих дней, в зависимости от нагрузки на банк.", tag: "Возврат средств" },

        { name: "Оформить через горячую линию", key: "l8", text: "Предлагаем оформить заказ на сайте dodopizza.ru или по номеру 78003330060.", tag: "Предлагаем" },
        { name: "Обновить приложение", key: "l9", text: "Видим в системе, что используете устаревшую версию приложения. Предлагаем обновить его, чтобы избежать возможных ошибок.", tag: "Предлагаем" },
        { name: "Оформить заказ после 00:00", key: "l10", text: "Принимаем заказы только день в день, вы можете оформить заказ после 00:00, выбрав необходимый интервал на отложенное время.", tag: "Предлагаем" },
        { name: "Найти информацию об акциях", key: "l210", text: "Промокоды и выгодные предложения найдёте на сайте dodopizza.ru, в разделе «Акции», а в приложении — в сторис над меню. Так же можно подписаться на тайную рассылку в группе ВК вашего города XXX. Готовые наборы со скидкой смотрите в разделе «Комбо».", tag: "Предлагаем" },
        { name: "Доставка доступна", key: "l10", text: "Видим в системе, что нет ограничений по доставке на ваш адрес. Попробуйте перезапустить приложение и почистить кэш устройства, чтобы данные обновились. Если ничего не изменится, то можно сделать заказ через сайт dodopizza.ru.", tag: "Предлагаем" },
        { name: "Изменить состав заказа", key: "l211", text: "Из-за технических особенностей системы не сможем изменить состав уже принятого заказа, но предлагаем отменить его, чтобы вы оформили новый с правильными данными. Деньги вернутся на карту в течение 3 рабочих дней, максимум 10, в зависимости от нагрузки банка. Как поступим?", tag: "Предлагаем" },
        { name: "Изменить адрес заказа (Заказ готовим)", key: "l212", text: "Ближайшая пиццерия не обслуживает адрес, который вы указали. Заказ уже готовим, его скоро можно будет забрать из ресторана на ХХХ или по адресу из заказа. Если это неудобно, отменим заказ, а вы оформите новый. Деньги вернутся на карту в течение 3, максимум 10 рабочих дней, срок зависит от нагрузки на банк. Как поступим?", tag: "Предлагаем" },
        { name: "Изменить адрес заказа (Курьер выехал)", key: "l213", text: "Ближайшая пиццерия не обслуживает адрес, который вы указали. Курьер уже выехал, сможете его встретить? Если неудобно принять его на указанном ранее адресе, отменим заказ, а вы оформите новый. Деньги вернутся на карту в течение 3, максимум 10 рабочих дней, срок зависит от нагрузки на банк. Как поступим?", tag: "Предлагаем" },
        { name: "Изменить адрес заказа (Неверная пиццерия)", key: "l214", text: "Не сможем выполнить просьбу о смене пиццерии из-за технических ограничений системы. Подскажите, получится забрать заказ по адресу: ХХХ? Если нет, то предлагаем отменить его: деньги вернутся на карту в течение 3, максимум 10 рабочих дней, срок зависит от нагрузки на банк. Какое решение подходит больше?", tag: "Предлагаем" },
        { name: "Изменить срок доставки заказа (Оформлен на ближайшее время)", key: "l215", text: "Вы оформили доставку на ближайшее время, поэтому изменить время не получится. Начинаем готовить сразу, как заказ попал в систему, чтобы привезти его в течение часа. Если неудобно ждать курьера, то отменим заказ, а вы оформите новый. Деньги вернутся на карту в течение 3, максимум 10 рабочих дней, срок зависит нагрузки на банк. Как поступим?", tag: "Предлагаем" },
        
        { name: "Отменить заказ за наличные", key: "l216", text: " Обращаем внимание, что после отмены заказа наличный способ оплаты будет доступен только через 24 часа — это особенность системы. Доставку нужно будет оплатить картой онлайн. Отменяем заказ?", tag: "Предлагаем" },
        { name: "Не сработал промокод", key: "l217", text: "Понимаем, как хотели получить скидку/подарок, но технически мы не можем поменять состав оформленного заказа. Можно сделать ещё один заказ и применить акцию. Перед оплатой проверьте корзину и убедитесь, что промокод сработал. Если появится какая-то ошибка, присылайте скриншот нам — поможем разобраться.", tag: "Предлагаем" },
        { name: "Не сработал промокод + отмена заказа", key: "l218", text: "Понимаем, как хотели получить скидку/подарок, но у нас нет технической возможности изменить состав оформленного заказа. Можем отменить текущий заказ, а вы сделаете новый с акцией. Деньги вернутся на карту в течение 3, максимум 10 рабочих дней, срок зависит от нагрузки на банк. Перед оплатой проверьте корзину и убедитесь, что промокод сработал. Если появится какая-то ошибка, присылайте скриншот нам — поможем разобраться.", tag: "Предлагаем" },
        { name: "Не сработал промокод из-за комбо", key: "l219", text: "Акция не сработала из-за комбо-набора в заказе. Промокод можно использовать в следующий раз, либо мы отменим текущий заказ, и вы оформите новый. Деньги вернутся на карту в течение 3, максимум 10 рабочих дней, срок зависит от нагрузки на банк. Как будет удобнее?", tag: "Предлагаем" },
       

        { name: "Отменили проблемный заказ", key: "lg239", text: "Курьер не смог дозвониться по телефону и в домофон, поэтому заказ отменили, а продукты утилизировали. К сожалению, не можем повторно доставить заказ и отправить промокод за опоздание.", tag: "Проблемный заказ" },
        { name: "Проблемный заказ подтвержён", key: "lg240", text: "Понимаем, ожидали получить промокод за опоздание, жаль, что сложилась такая ситуация. Купон выдаётся автоматически, только если не было препятствий для доставки. К сожалению, курьер не смог доставить заказ вовремя, так как не получилось дозвониться по телефону и в домофон. Соблюдаем наши принципы, чтобы обеспечить справедливое отношение ко всем клиентам.", tag: "Проблемный заказ" },
        { name: "Уточняем о проблемном заказе", key: "lg241", text: "Подскажите, были ли пропущенные вызовы? Был звонок в домофон?", tag: "Проблемный заказ" },
        { name: "Отправляем скидку за проблемный заказ", key: "lg242", text: "Ценим ваше доверие, но в этот раз не можем выдать промокод на пиццу. Отправляем промокод [ХХХ](dodo://applypromocode/ХХХ) на скидку 20%, его можно применить 1 раз в течение 14 дней. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями. Промокод также можно найти в приложении или на сайте dodopizza.ru, в разделе «Акции»\nНадеемся, что следующий заказ подарит только приятные эмоции.", tag: "Проблемный заказ" },

        { name: "Плохая оценка", key: "l11", text: "Видим в системе, что оценили заказ на X звезды. Подскажите, что именно не понравилось? Хотим разобраться и помочь", tag: "Уточняем" },
        { name: "Город и адрес", key: "l12", text: "Подскажете город и точный адрес доставки?", tag: "Уточняем" },
        { name: "Сколько напитка вылилось", key: "l13", text: "Подскажите, большая часть напитка пролилась?", tag: "Уточняем" },
        { name: "Фото", key: "l14", text: "Если есть фотография, то поделитесь ей, пожалуйста, — это поможет в проработке ошибок.", tag: "Уточняем" },
        { name: "Довоз от курьера", key: "l15", text: "Подскажите, обнаружили отсутствие при курьере? Обещал довезти?", tag: "Уточняем" },
        { name: "Скриншот ошибки промокода", key: "l16", text: "Если есть скриншот промокода и ошибки, то поделитесь им, пожалуйста", tag: "Уточняем" },
        { name: "Соус в прорези", key: "l17", text: "Скажите, проверяли специальные прорези для соуса в коробках?", tag: "Уточняем" },
        { name: "Доступность номера", key: "l18", text: "Подскажите, были ли пропущенные вызовы? Иногда мобильный оператор сам блокирует входящие звонки, или телефон находится в режиме полёта. Возможно, есть СМС о пропущенных вызовах? Телефон актуален для связи?", tag: "Уточняем" },
        { name: "Термосумка", key: "l19", text: "Курьер привёз заказ в термосумке?", tag: "Уточняем" },
        { name: "История операций в банке", key: "l20", text: "Подскажите, проверяли историю операций в мобильном банке? Пуш-уведомления и СМС о возврате могут не поступать, он просто отображается в зачислениях", tag: "Уточняем" },
        { name: "История операций в банке", key: "l21", text: "Подскажите, проверяли историю операций в мобильном банке?", tag: "Уточняем" },
        { name: "Не подошёл промокод", key: "l+21", text: "Подскажите, не устроил срок или сумма купона?", tag: "Уточняем" },

        { name: "Отказ от салфеток", key: "l22", text: "Больше не кладём салфетки. Хотим улучшить экологию: снизить вред от производства бумаги и уменьшить количество мусора.", tag: "Возражаем" },
        { name: "Почему без перчаток?", key: "l23", text: "Наши пиццамейкеры действительно готовят без использования перчаток. Вместо этого мы обрабатываем все поверхности и предметы на кухне безопасным дезинфицирующим средством, а руки тщательно моем каждые полчаса. Подробнее вы можете узнать на сайте bezperchatok.ru.", tag: "Возражаем" },
        { name: "Негативный отзыв/Жалоба в инстации", key: "l24", text: "Жаль, что сложилась такая ситуация, не можем препятствовать этому, но действительно хотим помочь в решении вопроса.", tag: "Возражаем" },
        { name: "Картофель в печи", key: "l25", text: "Картофель в пиццерии приходит уже готовым, пропускаем через печь, чтобы получить румяный оттенок. Подскажите, пробовали закуску впервые?", tag: "Возражаем" },
        { name: "Отказ от компенсации", key: "l26", text: "Понимаем, что написали нам не ради промокода, а для улучшения качества. В любом случае отправим команде информацию для проработки. Хотим порадовать при следующем заказе не только исправленной ошибкой, но и приятной скидкой. Согласны?", tag: "Возражаем" },
        { name: "Промокод не работает с комбо", key: "l27", text: "Промокод не суммируется с комбо, у вас в заказе есть набор (2 пиццы). Условия акции можно прочитать, нажав на неё или значок в правом верхнем углу ⓘ.", tag: "Возражаем" },
        { name: "Адрес недоступен", key: "l28", text: "Видим в системе, что адрес доступен к заказу. Попробуйте перезапустить приложение, или установить актуальную версию и попробовать оформить снова.", tag: "Возражаем" },
        { name: "Тёмная корка/горелый омлет", key: "l29", text: "Готовим омлет из полуфабрикатов, которые при запекании образуют тёмную корочку, что является допустимым. Подскажите, был ли вкус или запах гари?", tag: "Возражаем" },
        { name: "Примерное время доставки", key: "l30", text: "При выборе времени указано среднее доставки за прошедшую неделю в вашем городе. Доставляем заказ в течение 60 минут с момента оформления, но стараемся быстрее.", tag: "Возражаем" },
        { name: "Возврат длительный процесс", key: "l31", text: "Возврат денежных средств — длительный процесс, может занимать до 10 рабочих дней, решение о нём принимает управляющий пиццерии.", tag: "Возражаем" },
        { name: "Ограничение зоны доставки", key: "l32", text: "Ограничили зону доставки из-за перегруженности пиццерий. Не справляемся с потоком заказов, поэтому были вынуждены приостановить работу в вашем районе. Постараемся вернуться в строй как можно скорее.", tag: "Возражаем" },
        { name: "Ограничение зоны доставки 2", key: "l33", text: "Ограничили зону доставки из-за нехватки сотрудников кухни и курьеров. Не справляемся с нагрузкой, поэтому были вынуждены приостановить работу в вашем районе. Постараемся вернуть привычный сектор как можно скорее.", tag: "Возражаем" },
        { name: "Тёмные/горелые грибы", key: "l34", text: "При жарке грибов выделяется углекислый газ, который в них содержится. Когда он взаимодействует с высокой температурой - они темнеют. Подскажите, был ли вкус или запах гари?", tag: "Возражаем" },
        { name: "Тепловая полка и термосумка", key: "l35", text: "Заботимся о том, чтобы вы получали горячие продукты, поэтому после приготовления отправляем их на тепловую полку, а курьеру передаём в термосумке.", tag: "Возражаем" },
        { name: "Соразмерная компенсация", key: "l36", text: "Хотим помочь, поэтому стараемся подобрать компенсацию, соразмерную нашей ошибке. Предложенные промокоды — подходящее решение для такого случая. Какой отправим?", tag: "Возражаем" },
        { name: "Фиксируем обращения", key: "l37", text: "Каждое обращение фиксируем, затем коллеги в пиццерии прорабатывают допущенные ошибки. К сожалению, изменения не всегда происходят так оперативно, как хотелось бы. Но это не значит, что мы не обращаем внимания на проблемы, напротив, ищем для них оптимально решение. Не оставим без внимания ваше обращение, проработаем ошибку с командой и исправимся.", tag: "Возражаем" },
        { name: "Опоздание", key: "l38", text: "Стыдно, что привезли заказ остывшим. Проработаем скорость и постараемся исправиться. Видим в системе, что направили вам сертификат за опоздание в СМС. Подскажите, поступил?", tag: "Возражаем" },
        { name: "Картофель не во фритюре", key: "l39", text: "Готовим картофель в печи, а не во фритюре, масло в приготовлении не используем — добиваемся сбалансированного вкуса за счёт комбинации ингредиентов. Если в рецептах требуется масло, оно уже входит в составляющие продукта.", tag: "Возражаем" },
        { name: "Стоимость пасты (Уменьшенная порция)", key: "l239", text: "Стоимость ингредиентов постоянно растёт, поэтому мы решили протестировать уменьшенную порцию пасты, которая позволит избежать повышения цены на неё в меню.", tag: "Возражаем" },
        { name: "Холодные продукты (Нет опоздания)", key: "l240", text: "Понимаем, как неприятно получить продукт, который не оправдал ваших ожиданий. Иногда на ситуацию может повлиять холодная погода, и, несмотря на все усилия, мы не всегда можем гарантировать, что пицца будет такой же горячей, как в ресторане. Даже использование термосумки, специально разработанной для поддержания температуры, порой не решает эту проблему.", tag: "Возражаем" },
        { name: "Холодные продукты (Нет опоздания + Лоялим)", key: "l241", text: "Понимаем, что ситуация сложилась не так, как вы ожидали. В качестве компенсации отправляем скидку ХХ на следующий заказ. Промокодом [ХХХ](dodo://applypromocode/ХХХ) можно воспользоваться 1 раз в течение 14 дней. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями. Промокод можно найти в приложении или на сайте dodopizza.ru, в разделе «Акции».\nБудем стараться, чтобы вы точно насладились следующим заказом.", tag: "Возражаем" },
        { name: "Платная доставка", key: "l242", text: "Добавили возможность оплатить доставку отдельно, чтобы не приходилось набирать ненужные продукты в корзину до минимальной суммы. Цена формируется динамически, в зависимости от нагруженности пиццерии, количества сотрудников на смене и адреса доставки.", tag: "Возражаем" },
        
        { name: "Подозрение на отравление", key: "lv2_1", text: "Спасибо, что написали нам, это очень важно. Желаем, чтобы вы скорее поправились. Как сейчас самочувствие? Пожалуйста, расскажите подробнее о заказе — это поможет во всём разобраться. Какие-то продукты были на вкус не такими, как раньше? Во вкусе было что-то необычное? Мы закупаем ингредиенты у проверенных поставщиков, строго следим за хранением. Также соблюдаем стандарты чистоты: раз в два часа проводим дезинфекцию и влажную уборку рабочих поверхностей, регулярно моем руки. Тем важней нам сейчас разобраться, могло ли что-то пойти не так.", tag: "Проблемы" },
        { name: "Волос в продукте (Нет фото)", key: "lv2_2", text: "Простите за волос в ХХХ, понимаем, как было неприятно его обнаружить. Мы соблюдаем стандарты: собираем волосы в пучок или косичку, надеваем сеточки и кепки на голову. Видимо, в этот раз что-то пошло не так, поэтому хотим разобраться. Подскажите, волос был в начинке или на упаковке? Какого он цвета? Если есть фотография, то поделитесь ей, пожалуйста, — это поможет в проработке ошибок.", tag: "Проблемы" },
        { name: "Волос в продукте (Есть фото)", key: "lv2_3", text: "Простите за волос в ХХХ, понимаем, как было неприятно его обнаружить. Мы соблюдаем стандарты: собираем волосы в пучок или косичку, надеваем сеточки и кепки на голову. Видимо, в этот раз что-то пошло не так, поэтому хотим разобраться.", tag: "Проблемы" },
        
        { name: "Опоздание (Курьер выехал/В пути)", key: "lv2_4", text: "Простите, что опаздываем, курьер выехал в N, скоро будет у вас. Когда заберёте заказ, отправим СМС с промокодом на пиццу 35 см, которую сможете получить бесплатно 1 раз в течение года. Постараемся скорректировать график работы пиццамейкеров и курьеров, чтобы доставлять заказы вовремя и не подводить.", tag: "Опоздание" },
        { name: "Опоздание (Курьер выехал/В пути + Белгород)", key: "lv2_5", text: "Простите, что опаздываем, курьер выехал в N, скоро будет у вас. Постараемся скорректировать график работы пиццамейкеров и курьеров, чтобы доставлять заказы вовремя и не подводить вас.", tag: "Опоздание" },
        { name: "Опоздание Холд (Курьер не выехал)", key: "lv2_6", text: "Простите, что опаздываем. Сегодня много заказов, не успеваем всё приготовить, поэтому курьер ещё не выехал. Подождите, пожалуйста, 5-7 минут, узнаем у менеджера, когда заказ будет у вас.", tag: "Опоздание" },
        { name: "Опоздание Ответ (Курьер не выехал)", key: "lv2_7", text: "Простите за задержку, приедем к вам в течение N минут. За опоздание подарим промокод на пиццу 35 см, действует 1 раз в течение года. Если не готовы ждать, можем отменить заказ: деньги вернутся в течение 3-10 рабочих дней, и мы отправим вам промокод на пиццу 35 см от минимальной суммы, который будет действовать 1 раз в течение 14 дней. Как поступим?", tag: "Опоздание" },
        { name: "Опоздание Ответ (Курьер не выехал + Белгород)", key: "lv2_8", text: "Простите за задержку, приедем к вам в течение N минут. Если не готовы ждать, можем отменить заказ: деньги вернутся в течение 3 рабочих дней, максимум 10, и мы отправим вам промокод на пиццу 35 см, который можно применить 1 раз в течение 14 дней. Пиццу можно получить в подарок к заказу на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, предложение действует с комбо, но не работает с другими акциями. Чтобы промокод активировался, сумма заказа вместе с подарочным продуктом должна быть выше минимальной на доставку.", tag: "Опоздание" },
        { name: "Не пришёл промокод/извинительная пицца за опоздание", key: "lv2_9", text: "Простите, что опоздали и не отправили код автоматически. Постараемся скорректировать график работы пиццамейкеров и курьеров, чтобы доставлять заказы вовремя и не подводить вас.", tag: "Опоздание" },

       
        { name: "Массовый сбой", key: "lv2_10", text: "К сожалению, в системе произошёл массовый сбой, знаем о проблеме и стараемся решить её как можно скорее.", tag: "Списания" },
        { name: "Сбой при оплате", key: "lv2_11", text: "К сожалению, произошёл сбой, оплата не прошла, поэтому не сможем выполнить этот заказ, простите. Деньги вернутся на карту автоматически в течение 3 рабочих дней, максимум 10. Напишите снова, пожалуйста, если этого не произойдёт", tag: "Списания" },
        { name: "Списание без заказа", key: "lv2_12", text: "Видим в системе, что у вас не оформлен заказ. Оплата не поступила в пиццерию и автоматически вернётся к вам. Процесс может занять 1-3 рабочих дня, максимум 10, в зависимости от нагрузки на банк. Если через 10 дней деньги так и не вернутся, напишите снова, пожалуйста, — проверим транзакцию. Пока предлагаем оформить заказ наличным способом оплаты через приложение, на сайте dodopizza.ru или по номеру 78003020060.", tag: "Списания" },
        { name: "Списание без заказа (CAKE)", key: "lv2_13", text: "Это не нормальная практика, но подобное иногда может произойти из-за проблем на стороне эквайринга, ошибки устройства или внутри системы. Сожалеем, что во время оплаты возникли трудности. Сроки возврата средств зависят только от работы банка, мы хотели бы помочь ускорить процесс, но не имеем такой возможности. В качестве компенсации предлагаем воспользоваться промокодом [CAKE](dodo://applypromocode/CAKE) на пиццу Мясная 25 см, действует при заказе от 1199 рублей, не работает с комбо и на дополнительные ингредиенты.", tag: "Списания" },


        { name: "Не можем выдать промокод", key: "lv2_14", text: "К сожалению, из-за неполадок в системе не можем отправить промокод прямо сейчас. Знаем о проблеме и уже передали информацию техническим специалистам. Вернёмся к вам и вышлем акцию после исправления ошибок.", tag: "Сбой" },


        { name: "Отменили заказ", key: "l40", text: "Готово, заказ отменили, можно оформлять на актуальный адрес.", tag: "Завершаем диалог" },
        { name: "Добавили комментарий к заказу", key: "l41", text: "Готово, дополнили комментарий к адресу. Спасибо, что предупредили.", tag: "Завершаем диалог" },
        { name: "Оформили замену", key: "l42", text: "Готово, оформили замену в системе, курьер будет у вас в течение 40-60 минут, постараемся быстрее.", tag: "Завершаем диалог" },
        { name: "Отправляем промокод", key: "l43", text: "Ваш промокод — [ХХХ](dodo://applypromocode/ХХХ), его можно найти в приложении или на сайте dodopizza.ru, в разделе «Акции». Спасибо, что даёте шанс исправиться, постараемся порадовать в следующий раз.", tag: "Завершаем диалог" },
        { name: "Отправляем промокод KZ", key: "l44", text: "Ваш промокод — [ХХХ](dodo://applypromocode/ХХХ), его можно найти в приложении или на сайте dodopizza.kz, в разделе «Акции». Спасибо, что даёте шанс исправиться, постараемся порадовать в следующий раз.", tag: "Завершаем диалог" },

        { name: "Промокод на день рождения", key: "l45", text: "Укажите дату рождения в личном кабинете, и в течение часа в нём появится один из случайных наборов с двумя промокодами. Чтобы его использовать, нужно выбрать продукты, затем перейти в раздел «Акции» и применить скидку, она отобразится в корзине. При заказе на доставку, в ресторане и на самовывоз нужно набрать минимальную сумму, она может меняться в зависимости от нагрузки в пиццерии. Промокод можно применить только в приложении, он действует 1 раз в течение двух недель: за 3 дня до, в праздник и 10 дней после, скидка не суммируется с комбо и другими акциями.", tag: "Промокоды" },
        { name: "Промокод на день рождения (Дата указана менее часа назад)", key: "l45+", text: "В течение часа в личном кабинете появится один из случайных наборов с двумя промокодами. Чтобы его использовать, нужно выбрать продукты, затем перейти в раздел «Акции» и применить скидку, она отобразится в корзине. При заказе на доставку, в ресторане и на самовывоз нужно набрать минимальную сумму, она может меняться в зависимости от нагрузки в пиццерии. Промокод можно применить только в приложении, он действует 1 раз в течение двух недель: за 3 дня до, в праздник и 10 дней после, скидка не суммируется с комбо и другими акциями.", tag: "Промокоды" },
        
        {
            name: "Промокод на день рождения (Дата указана)", key: "l46", text: `
            В день рождения дарим набор из двух промокодов, найти их можно в Профиле → «Акции».\nПроверили систему и увидели ваши предложения:\n
            — Скидка ХХХ% при заказе от 649 рублей по промокоду [ХХХ](dodo://applypromocode/ХХХ);
            — Скидка ZZZ при заказе от YYY рублей по промокоду [ХХХ](dodo://applypromocode/ХХХ);
            — Пицца QQQ 25 см при заказе от 1049 рублей по промокоду [ХХХ](dodo://applypromocode/ХХХ).\n\nВоспользоваться акциями можно при оформлении заказа в приложении 1 раз в течение 2 недель: за 3 дня до, в праздник и 10 дней после него. Действует на доставку от минимальной суммы, самовывоз и в ресторане, купон не суммируется с другими предложениями и комбо.
        `, tag: "Промокоды"
        },
        {
            name: "Промокод на день рождения KZ", key: "l246", text: `
            В день рождения дарим скидку 20%. Укажите дату рождения в личном кабинете, и в течение часа в нём появится промокод. Чтобы его использовать, нужно выбрать продукты, затем перейти в раздел «Акции» и применить скидку, она отобразится в корзине. Итоговая сумма заказа должна быть не меньше минимальной для доставки.
            Промокод действует 1 раз в течение двух недель: за 3 дня до, в праздник и 10 дней после. Заказ можно оформить на доставку, самовывоз и в ресторане, скидка не суммируется с другими акциями, но работает с комбо. На комбо и дополнительные ингредиенты промокод не действует, только на другие продукты в заказе.
        `, tag: "Промокоды"
        },
        { name: "Промокод на день рождения (Сгенерирован вручную в тикете)", key: "lv2_15", text: "Ваш промокод на скидку 20% в честь дня рождения — [ХХХ](dodo://applypromocode/ХХХ). Можно воспользоваться 1 раз в течение 7 дней при заказе на доставку от минимальной суммы, самовывоз и в ресторане. Купон не суммируется с другими акциями, но работает с комбо. На сам набор и дополнительные ингредиенты промокод не действует, только на другие продукты в заказе. Применить можно по нажатию в тексте или на сайте dodopizza.ru и в приложении → Профиль → «Акции».", tag: "Промокоды" },
        { name: "Промокод на день рождения KZ (Сгенерирован вручную в тикете)", key: "lv2_16", text: "Ваш промокод на скидку 20% в честь дня рождения — [ХХХ](dodo://applypromocode/ХХХ). Можно воспользоваться 1 раз в течение 7 дней при заказе на доставку от минимальной суммы, самовывоз и в ресторане. Купон не суммируется с другими акциями, но работает с комбо. На сам набор и дополнительные ингредиенты промокод не действует, только на другие продукты в заказе. Применить можно по нажатию в тексте или на сайте dodopizza.kz и в приложении → Профиль → «Акции».", tag: "Промокоды" },

        { name: "Промокод 20PIZZA", key: "l247", text: "[20PIZZA](dodo://applypromocode/20PIZZA) — скидка 20% на большую пиццу для новых клиентов. Выберите любую пиццу 35 см, затем введите промокод в корзине. Работает 1 раз на доставку, самовывоз и в ресторане, не суммируется с другими акциями и комбо", tag: "Промокоды" },
        
        { name: "Промокод CAKE", key: "l47", text: "Предлагаем применить промокод [CAKE](dodo://applypromocode/CAKE) — Мясная пицца 25 см в подарок от 1199 рублей на доставку, в ресторане и на самовывоз, не работает с комбо и на дополнительные ингредиенты, не суммируется с другими акциями. Действует до 30 сентября 2024 года, пока продукция есть в наличии.", tag: "Промокоды" },
        { name: "Промокод CAKE KZ", key: "l48", text: "Предлагаем применить промокод [CAKE](dodo://applypromocode/CAKE) — Мясная пицца 25 см в подарок от 7000 тенге на доставку, в ресторане и на самовывоз, не работает с комбо и на дополнительные ингредиенты, не суммируется с другими акциями. Действует до 30 сентября 2024 года, пока продукция есть в наличии.", tag: "Промокоды" },
        { name: "Промокод ВиС", key: "l49", text: "Дарим промокод [ХХХ](dodo://applypromocode/ХХХ) на маленькую пиццу Ветчина и сыр при заказе от 890 рублей на доставку, в ресторане и на самовывоз. Работает один раз на номер телефона, не суммируется с другими акциями, но активен с комбо.", tag: "Промокоды" },
        { name: "Промокод ВиС KZ", key: "l50", text: "Дарим промокод [ХХХ](dodo://applypromocode/ХХХ) на маленькую пиццу Ветчина и сыр при заказе от 3000 тенге на доставку, в ресторане и на самовывоз. Работает один раз на номер телефона, не суммируется с другими акциями, но активен с комбо.", tag: "Промокоды" },
    
        { name: "Как использовать додокоины (Android)", key: "app1", text: "Перейдите в профиль в раздел «Додокоины», откроется меню, в котором можно выбрать продукты за бонусы. Отправляем скриншот для наглядности", img: "https://i.ibb.co/SNhDc8v/dodocoins.png", tag: "Работа мобильного приложения/МП" },
        { name: "Как удалить продукт из корзины (Android)", key: "app2", text: "Перейдите в корзину и нажмите на минус рядом с продуктами. Отправляем скриншот для наглядности", img: "https://i.ibb.co/8KJCqPd/removecart.png", tag: "Работа мобильного приложения/МП" },
        { name: "Как указать время получения заказа (Android)", key: "app3", text: "На этапе оформления заказа выберите удобное время. Отправляем скриншот для наглядности", img: "https://i.ibb.co/1TtrbTL/time.png", tag: "Работа мобильного приложения/МП" },
        { name: "Как указать время доставки (Android)", key: "app3+", text: "На этапе оформления заказа выберите удобный интервал времени. Отправляем скриншот для наглядности", img: "https://i.ibb.co/5hby7DQ/image.png", tag: "Работа мобильного приложения/МП" },
        { name: "Как изменить состав пиццы (Android)", key: "app4", text: "Чтобы изменить состав, перед добавлением пиццы в корзину нажмите «Убрать ингредиенты» или выберите нужный ингредиент из списка ниже. Если пицца входит в комбо-набор, выберите продукт и перейдите в раздел «Изменить состав». Отправляем скриншот для наглядности", img: "https://i.ibb.co/X5kk2XN/customize.png", tag: "Работа мобильного приложения/МП" },
        
        { name: "Как добавить карту (Android/iOS)", key: "app5", text: "Добавить карту можно только при оформлении заказа. Выберите товары, затем перейдите в «Корзину» → «Оформить» → «Оплата» → «Картой в приложении», потом вводите данные карты. Отправляем скриншот для наглядности", img: "https://i.ibb.co/87PGncR/image.png", tag: "Работа мобильного приложения/МП" },
        { name: "Как удалить карту (Android)", key: "app6", text: "Удалить карту можно только на этапе оплаты заказа. Выберите товары, затем перейдите в «Корзину» → «Оформить» → «Оплата». Нажмите на карандаш, укажите карту и нажмите на корзину. Отправляем скриншот для наглядности", img: "https://i.ibb.co/X7xW3PG/image.png", tag: "Работа мобильного приложения/МП" },
        { name: "Как удалить карту (iOS)", key: "app7", text: "Удалить карту можно только на этапе оплаты заказа. Выберите товары, затем перейдите в «Корзину» → «Оформить» → «Оплата». Нажмите на «Править», затем на красный минус рядом с картой. Отправляем скриншот для наглядности", img: "https://i.ibb.co/L05ZPSX/image.png", tag: "Работа мобильного приложения/МП" },
        { name: "Оформить доставку (Android/iOS)", key: "app8", text: "На главном экране, сверху, жмёте на сохранённый адрес, затем на нужный вариант получения заказа — «Доставка». Укажите адрес доставки и можно оформлять заказ. Отправляем скриншот для наглядности", img: "https://i.ibb.co/zm1tGFS/image.png", tag: "Работа мобильного приложения/МП" },
        { name: "Оформить на самовывоз (Android/iOS)", key: "app9", text: "На главном экране, сверху, жмёте на сохранённый адрес, указываете нужный вариант получения заказа — «В пиццерии», затем выбираете удобный ресторан и нажимаете на кнопку «Заказать здесь». Отправляем скриншот для наглядности", img: "https://i.ibb.co/tQRPcDJ/image.png", tag: "Работа мобильного приложения/МП" },
        { name: "Указать адрес (Android/iOS)", key: "app10", text: "В главном меню нажмите на значок профиля → «Адреса доставки», введите адрес → «Сохранить»/«Доставить сюда». Система автоматически его запомнит. Если планируете оставить комментарий, сделать это можно сейчас. Отправляем скриншот для наглядности", img: "https://i.ibb.co/cv83p9P/image.png", tag: "Работа мобильного приложения/МП" },
        { name: "Поменять комментарий к адресу (Android/iOS)", key: "app11", text: "На странице с меню, вверху, нажмите на прежний адрес, комментарий для курьера получится указать справа при добавлении нового адреса. Отправляем скриншот для наглядности", img: "https://i.ibb.co/8cH1BBW/image.png", tag: "Работа мобильного приложения/МП" },
        { name: "Открыть корзину (Android/iOS)", key: "app12", text: "Чтобы корзина появилась, нужно что-нибудь в неё добавить. Выберите любой продукт и нажмите на него. Отправляем скриншот для наглядности", img: "https://i.ibb.co/HV74mj0/image.png", tag: "Работа мобильного приложения/МП" },
        { name: "Ввести промокод (Android/iOS)", key: "app13", text: "Перейдите в корзину и пролистайте вниз, пока не увидите поле с вводом промокода. Отправляем скриншот для наглядности", img: "https://i.ibb.co/9Vp6J9p/image.png", tag: "Работа мобильного приложения/МП" },
        { name: "Выбрать способ оплаты (Android/iOS)", key: "app14", text: "До оформления заказа нажмите на стрелочку рядом с разделом «Оплата», которая находится в корзине, затем выберите любой удобный способ. Отправляем скриншот для наглядности", img: "https://i.ibb.co/7SKCpfv/image.png", tag: "Работа мобильного приложения/МП" },
        { name: "Удалить аккаунт (Android/iOS)", key: "app15", text: "Перейдите в «Профиль» → «Настройки», долистайте до конца и нажмите «Удалить аккаунт». Отправляем скриншот для наглядности", img: "https://i.ibb.co/BBbL8rL/image.png", tag: "Работа мобильного приложения/МП" },
        { name: "Не удалился ингредиент (Android)", key: "app16", text: "Проверили состав заказа: ингредиент не был удалён из XXX, поэтому продукт приготовили с ним. Перед оформлением можно убедиться, какие ингредиенты убрали. Отправляем скриншот для наглядности", img: "https://i.ibb.co/LPcPRCD/photo-2024-10-25-10-40-26.jpg", tag: "Работа мобильного приложения/МП" },
        { name: "Не удалился ингредиент (Лояльность)", key: "app16+", text: "Понимаем, что ситуация неприятная, и, несмотря на отсутствие ошибки с нашей стороны, хотим сгладить негативные эмоции. Надеемся, это немного скрасит впечатление.", tag: "Работа мобильного приложения/МП" },
        { name: "Указать детали к заказу", key: "app17", text: "Перед оформлением заказа в профиле, в разделе «Адреса доставки», можно указать подъезд, этаж и квартиру. Если номер неактуален для связи, добавьте просьбу позвонить на новый в комментарии или войдите через него в приложение. Отправляем скриншот для наглядности.", img: "https://i.ibb.co/QK8wjf0/actualdata.png", tag: "Работа мобильного приложения/МП" },
       
    ];

    const combinedOptions = useMemo(() => [
        ...userTemplates.map(template => ({ ...template, key: `db-${template.id}` })), 
        ...options
    ], [userTemplates, options]);

    const sortedAndFilteredOptions = useMemo(() => {
        const userTemplateKeys = userTemplates.map(template => template.key);

        const sortedOptions = combinedOptions.sort((a, b) => {
            if (a.tag !== b.tag) return a.tag.localeCompare(b.tag);
            if (userTemplateKeys.includes(a.key) && !userTemplateKeys.includes(b.key)) return -1;
            if (!userTemplateKeys.includes(a.key) && userTemplateKeys.includes(b.key)) return 1;
            return 0;
        });
      
        return sortedOptions.filter(option =>
            option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.tag.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.text.toLowerCase().includes(inputValue.toLowerCase())
        );

        
    }, [combinedOptions, inputValue, userTemplates]);


    console.log('sortedAndFilteredOptions', sortedAndFilteredOptions);

    return (
        <Autocomplete
            multiple
            options={sortedAndFilteredOptions}
            groupBy={(option) => option.tag}
            getOptionLabel={(option) => option.name}
            filterOptions={(options) => options}
            renderInput={(params) => (
                <TextField 
                    {...params} 
                    label="Выбери шаблоны" 
                    onChange={(event) => setInputValue(event.target.value)}  // Обновление значения при изменении ввода
                />
            )}
            renderOption={(props, option) => (
                <Tooltip title={option.text} arrow>
                    <Box component="li" {...props} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Chip
                            label={option.tag}
                            size="small"
                            sx={{ backgroundColor: option.id ? 'green' : 'default', color: 'white' }}
                        />
                        <span style={{ paddingLeft: '5px' }}>{`${option.name}`}</span>
                    </Box>
                </Tooltip>
            )}
            renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                    <Chip
                        key={option.key || option.id}
                        label={option.tag}
                        {...getTagProps({ index })}
                        sx={{ backgroundColor: option.id ? 'green' : 'default', color: 'white' }}
                    />
                ))
            }
            onChange={handleTemplatesChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
        />
    );
}




export default Templates;